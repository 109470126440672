<template>
  <div>
    <h1>Monitoring Data Master Tug Boat</h1>
    <BCardActions
      title="Permintaan Pelayanan Kapal & Barang"
      sub-title="Semoga hari anda menyenangkan dan tetap semangat 📌"
      action-collapse
      class="mt-1"
      :collapsed="false"
      :no-actions="true"
    >
      <RiverRouteTugBoatTable />
    </BCardActions>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import RiverRouteTugBoatTable from './RiverRouteTugBoatTable.vue'

export default {
  components: {
    BCardActions,
    RiverRouteTugBoatTable
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  }
}
</script>
